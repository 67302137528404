import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import * as firebase from 'firebase/app'
import Cookies from 'universal-cookie'
import { Box } from '@rebass/emotion'
import { InputField } from 'components/FormFields'
import { ErrorMessage, FormHeader, StyledButton, StyledLink } from 'components/FormStyles'

import { FormBox, FormFlex, FormFlexInner, FormWrapper, FormWrapperBox } from 'components/FormLayout'
import { INPUT_WIDTH, setLoginFlag, getLoginFlag, unsetLoginFlag } from 'utils/formHelpers'
import Loader from 'components/Loader'
import { navigateToPathHistory } from 'components/PathHistory'
import { COLORS } from 'utils/styleHelpers'
import { userActions, authActions } from 'wrFirebase'
import track, { TrackingPropType } from 'react-tracking'
import { EVENT_TYPES, TRACKING_ACTIONS } from 'utils/constants'
import { stampTime, eventDispatch } from 'utils/tracker'
import { SessionContext } from 'components/AuthContext'

const { doCreateUser, doGetUser } = userActions

function LoginForm({ tracking }) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	useEffect(() => {
		async function handleAuthRedirect(uid) {
			const result = await firebase.auth().getRedirectResult()
			if (result.user) {
				const { user } = result
				const { uid, displayName, email, emailVerified } = user
				const { exists } = await doGetUser(uid)
				if (!emailVerified) {
					// prevent bots and spam
					setError('Your Google Account is not verified')
					setIsLoading(false)
				} else if (!exists) {
					// getting only one field with a name.  so have to split if there is a space
					const name = displayName.indexOf(' ') >= 0 ? displayName.split(' ') : [displayName]
					const firstName = name[0]
					const lastName = name.length > 1 ? name[1] : ''
					const cookies = new Cookies()
					const usernameCookie = cookies.get('username')
					const prefersUsernameCookie = cookies.get('prefersUsername')
					const username = usernameCookie === 'null' ? null : usernameCookie
					const prefersUsername = prefersUsernameCookie === 'true'
					const isAgeVerified = cookies.get('ageVerified')
					cookies.remove('prefersUsername')
					cookies.remove('username')

					await doCreateUser({
						id: uid,
						username,
						prefersUsername,
						firstName,
						lastName,
						email,
						provider: 'google',
						isAgeVerified,
					}).then(() => {
						navigateToPathHistory()
					})
				} else {
					navigateToPathHistory()
				}
			}
		}
		if (getLoginFlag()) {
			setIsLoading(true)
			handleAuthRedirect(uid)
			unsetLoginFlag()
		}

		return () => {
			setIsLoading(false)
			unsetLoginFlag()
		}
	}, [])

	const onSubmit = async () => {
		setIsLoading(true)
		try {
			await authActions.doSignInWithEmailAndPassword(email, password)
			tracking.trackEvent({
				event: EVENT_TYPES.WR_LOGIN,
				action: TRACKING_ACTIONS.LOGIN_ACTION,
				sessionID,
				uid,
				userIp,
				userLocation,
				version,
			})
			setIsLoading(false)
			navigateToPathHistory()
		} catch (error) {
			const { message } = error
			setIsLoading(false)
			setError({ message })
		}
	}

	const onGoogleLogin = async event => {
		try {
			event.preventDefault()
			setIsLoading(true)
			setLoginFlag()
			await authActions.doSignInWithGoogle()
			navigateToPathHistory()
		} catch (error) {
			const { message } = error
			setIsLoading(false)
			setError({ message })
		}
	}

	const setEmailInputState = event => {
		if (error) {
			setError(null)
		}
		setEmail(event.target.value)
	}

	const setPasswordInputState = event => {
		if (error) {
			setError(null)
		}
		setPassword(event.target.value)
	}

	const isInvalid = password === '' || email === ''
	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<FormWrapper>
					<FormWrapperBox>
						<FormHeader>Login</FormHeader>
						<form>
							<FormFlex>
								<FormBox>
									<InputField
										onChange={setEmailInputState}
										placeholder="Email Address"
										type="text"
										value={email}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<InputField
										onChange={setPasswordInputState}
										placeholder="Password"
										type="password"
										value={password}
										width={INPUT_WIDTH}
									/>
								</FormBox>
								<FormBox>
									<FormFlexInner>
										<Box py={2} width={1}>
											<StyledButton bg={COLORS.BLACK} disabled={isInvalid} onClick={onSubmit}>
												Sign In with Email
											</StyledButton>
										</Box>
										<Box py={2} width={1}>
											<StyledButton bg={COLORS.RED} onClick={onGoogleLogin}>
												Sign in with Google
											</StyledButton>
										</Box>
									</FormFlexInner>
								</FormBox>
								<FormBox>
									<StyledLink to="/register">Create an Account</StyledLink>
								</FormBox>
								<FormBox>
									<StyledLink to="/forgotpassword">Forgot Your Password?</StyledLink>
								</FormBox>
								{error && (
									<FormBox>
										<ErrorMessage>{error.message}</ErrorMessage>
									</FormBox>
								)}
							</FormFlex>
						</form>
					</FormWrapperBox>
				</FormWrapper>
			)}
		</>
	)
}
LoginForm.propTypes = {
	tracking: TrackingPropType,
}
export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(LoginForm)
